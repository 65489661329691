import React, { useContext, useEffect, useState } from 'react'
import {
  Alert,
  Box,
  Card,
  CardContent,
  Container,
  FormControlLabel,
  Grid,
  Typography,
  useTheme,
  Checkbox,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import axios from 'axios'
import qs from 'qs'
import {
  usCurrency,
  indonesiaCurrency,
  IdrToUsd,
  usdToIdr,
} from '../lib/utilities'
import { store } from '../components/store'

export default function ReviewPurchase() {
  const navigate = useNavigate()
  const { dispatch, state } = useContext(store)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const theme = useTheme()
  const [errorMessage, setErrorMessage] = useState('')
  const [sessionId, setSessionId] = useState(null)

  const calculateSalesTax = async () => {
    let tax = 0
    if (state.isIndonesia && state.packageInfo.id > 0) {
      tax =
        ((state.registration.program === 0
          ? state.packageInfo.indonesiaFeeCustomer
          : state.packageInfo.indonesiaFeeAssociate || state.packageInfo.fee) +
          state.packageInfo.monthlyPlan) *
        0.11
    }

    dispatch({ type: 'SET_TAX_AMOUNT', payload: tax })
  }

  const createOrderPayload: any = {
    xid: state.memberId,
    package: state.packageInfo.id,
    qty: state.packageInfo.qty,
  }

  if (!state.registration.npwp) {
    createOrderPayload.nik = state.registration.nik
  }

  if (!state.registration.nik) {
    createOrderPayload.npwp = state.registration.npwp
  }

  if (state.country !== 'ID') {
    delete createOrderPayload.nik
    delete createOrderPayload.npwp
  }

  const getOrderId = async () => {
    const { data } = await axios({
      url: 'https://signup.talkfusion.com/order/join/x_orderProcess_a.asp',
      method: 'POST',
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
      },
      data: qs.stringify(createOrderPayload),
    })

    // eslint-disable-next-line no-eval
    const order = eval(`(${data})`)

    if (order.sessionId) {
      setSessionId(order.sessionId)
    }

    // Override order amount if Indonesia and no package selected
    if (state.isIndonesia && state.registration.planId === 0) {
      order.amount = 177600
    }

    dispatch({
      type: 'SET_ORDER',
      payload: {
        orderId: order.orderId,
        amount:
          state.country === 'ID' && state.registration.planId !== 0
            ? IdrToUsd(order.amount)
            : order.amount,
        tax: state.country === 'ID' ? IdrToUsd(order.tax) : order.tax,
        idrAmount: state.registration.planId !== 0 ? order.amount : 160000,
        idrTax: state.registration.planId !== 0 ? order.tax : 17600,
      },
    })
  }

  const createTFAPPAccount = async () => {
    const createAccountPayload: any = {
      sponsorId: state.sponsor.account?.accountNumber,
      placementId: state.placementId,
      program: state.registration.program,
      side: state.placement,
      planId: state.registration.planId,
      packageId: state.registration.packageId,
      fd14: state.registration.birthDate.text,
      country: state.country,
      fd10: state.registration.password,
      fd11: state.registration.confirmPassword,
      fd12: state.registration.firstName,
      fd13: state.registration.lastName,
      fd15: state.registration.email,
      fd16: state.registration.confirmEmail,
      'date-of-birth': state.registration.birthDate.text,
      fd17: state.registration.company,
      fd23: state.registration.phone,
      fd18: state.registration.addressLine1,
      fd19: state.registration.addressLine2,
      fd20: state.registration.city,
      fd21: state.registration.stateProvince,
      fd22: state.registration.postalCode,
    }

    // Birthdate not required for Indonesian country
    if (state.isIndonesia) {
      delete createAccountPayload['date-of-birth']
      createAccountPayload.npwp = state.registration.npwp
      createAccountPayload.nik = state.registration.nik
    }

    // Set planId based on the package
    if (state.registration.packageId === 0) {
      createAccountPayload.planId = 0 // if user is not interested in a product package, planId is 0
    } else if (state.registration.packageId === 1) {
      createAccountPayload.planId = 'Q' // if the user chose "Video Email" (packageId 1), then planId = Q
    } else {
      createAccountPayload.planId = 'S' // planId is S if user selects Elite, Executive, or Pro
    }

    return axios({
      method: 'POST',
      url: 'https://signup.talkfusion.com/jointf/_create_account.asp',
      data: qs.stringify(createAccountPayload),
    })
  }

  useEffect(() => {
    getOrderId()

    if (state.packageInfo.id > 0) {
      calculateSalesTax()
    }
  }, [state.packageInfo.monthlyPlan])

  const nextPage = async () => {
    setLoading(true)

    if (state.isTFAPP) {
      const { data: memberId } = await createTFAPPAccount()

      const { data } = await axios({
        url: 'https://signup.talkfusion.com/order/join/x_orderProcess_a.asp',
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
        },
        data: qs.stringify({
          xid: memberId,
          package: state.packageInfo.id,
          qty: state.packageInfo.qty,
        }),
      })

      // eslint-disable-next-line no-eval
      const order = eval(`(${data})`)
      setLoading(false)

      if (order.sessionId) {
        window.location.href = `https://signup.talkfusion.com/join/_appWelcome2022.asp?sessionId=${order.sessionId}`
      }

      return
    }

    setLoading(false)
    navigate('/payment')
  }

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.grey[100],
      }}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          py: 8,
        }}
      >
        <Card sx={{ mb: 2 }}>
          <CardContent sx={{ p: 4 }}>
            {errorMessage && (
              <Alert severity="error" sx={{ mb: 3 }}>
                {errorMessage}
              </Alert>
            )}
            <Grid container spacing={12}>
              <Grid item xs={12} lg={3}>
                <Typography>ACCOUNT INFORMATION</Typography>
              </Grid>
              <Grid item xs={12} lg={3}>
                <Typography sx={{ borderBottom: '1px solid red' }}>
                  REVIEW PURCHASE
                </Typography>
              </Grid>
              <Grid item xs={12} lg={3}>
                <Typography>PAYMENT</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ paddingTop: '2rem' }}>
              <Grid item xs={12}>
                <Typography variant="h4">Purchase Overview</Typography>
              </Grid>
            </Grid>
            {/* {!state.indonesiaDomain && (
              <Grid
                container
                spacing={3}
                sx={{
                  paddingTop: '2rem',
                  paddingBottom: '1rem',
                  borderBottom: '1px solid lightgrey',
                }}
              >
                <Grid item xs={6}>
                  <Typography>One Time Associate Fee</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>{usCurrency(39)}</Typography>
                </Grid>
              </Grid>
            )} */}
            {state.packageInfo.id > 0 && (
              <>
                <Grid
                  container
                  spacing={3}
                  sx={{
                    paddingTop: '2rem',
                    paddingBottom: '1rem',
                    borderBottom: '1px solid lightgrey',
                  }}
                >
                  <Grid item xs={6}>
                    <Typography>
                      {state.isIndonesia
                        ? state.packageInfo.indonesiaName ||
                          state.packageInfo.name
                        : state.packageInfo.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      {state.isIndonesia
                        ? indonesiaCurrency(
                            usdToIdr(
                              (state.registration.program === 0
                                ? state.packageInfo.indonesiaFeeCustomer
                                : state.packageInfo.indonesiaFeeAssociate) ||
                                state.packageInfo.fee
                            )
                          )
                        : usCurrency(state.packageInfo.fee)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  sx={{
                    paddingTop: '2rem',
                    paddingBottom: '1rem',
                    borderBottom: '1px solid lightgrey',
                  }}
                >
                  <Grid item xs={6}>
                    <Typography>
                      {state.isIndonesia ? 'Database Manager' : 'Monthly Plan'}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      {state.isIndonesia
                        ? indonesiaCurrency(
                            usdToIdr(
                              state.packageInfo.indonesiaMonthlyPlan *
                                (state.registration.program === 0 ? 1.1 : 1)
                            )
                          )
                        : usCurrency(state.packageInfo.monthlyPlan)}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
            {state.isIndonesia &&
              state.registration.program === 1 &&
              state.registration.planId !== 0 && (
                <Grid
                  container
                  spacing={3}
                  sx={{
                    paddingTop: '2rem',
                    paddingBottom: '1rem',
                    borderBottom: '1px solid lightgrey',
                  }}
                >
                  <Grid item xs={6}>
                    <Typography>Starter Kit</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>IDR 160,000</Typography>
                  </Grid>
                </Grid>
              )}
            {state.isIndonesia && state.registration.planId === 0 && (
              <>
                <Grid
                  container
                  spacing={3}
                  sx={{
                    paddingTop: '2rem',
                    paddingBottom: '1rem',
                    borderBottom: '1px solid lightgrey',
                  }}
                >
                  <Grid item xs={6}>
                    <Typography>Starter Kit </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>IDR 160,000</Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  sx={{
                    paddingTop: '2rem',
                    paddingBottom: '1rem',
                    borderBottom: '1px solid lightgrey',
                  }}
                >
                  <Grid item xs={6}>
                    <Typography>11% VAT</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>IDR 17,600</Typography>
                  </Grid>
                </Grid>
              </>
            )}
            {state.registration.planId !== 0 && (
              <Grid
                container
                spacing={3}
                sx={{
                  paddingTop: '2rem',
                  paddingBottom: '1rem',
                  borderBottom: '1px solid lightgrey',
                }}
              >
                <Grid item xs={6}>
                  <Typography>
                    {state.isIndonesia ? '11% VAT' : 'Sales Tax'}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    {state.isIndonesia
                      ? indonesiaCurrency(state.order.idrTax)
                      : usCurrency(state.order.tax)}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {state.packageInfo.id > 0 && (
              <Grid
                container
                sx={{ paddingTop: '4rem', paddingBottom: '4rem' }}
              >
                <Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.prePayDiscount === 10}
                          onChange={({ target: { checked } }) => {
                            dispatch({
                              type: 'SET_PREPAY_DISCOUNT',
                              payload: checked ? 10 : 0,
                            })
                            calculateSalesTax()
                          }}
                        />
                      }
                      label={
                        <>
                          <Typography
                            variant="h5"
                            sx={{ display: 'inline-block' }}
                          >
                            SAVE 10%
                          </Typography>
                          <Typography>
                            by pre-paying your 1 year Monthly Plan
                          </Typography>
                        </>
                      }
                    />
                  </Grid>
                </Grid>
                <Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.prePayDiscount === 15}
                          onChange={({ target: { checked } }) => {
                            dispatch({
                              type: 'SET_PREPAY_DISCOUNT',
                              payload: checked ? 15 : 0,
                            })
                            calculateSalesTax()
                          }}
                        />
                      }
                      label={
                        <>
                          <Typography
                            variant="h5"
                            sx={{ display: 'inline-block' }}
                          >
                            SAVE 15%
                          </Typography>
                          <Typography>
                            by pre-paying your 3 year Monthly Plan
                          </Typography>
                        </>
                      }
                    />
                  </Grid>
                </Grid>
                <Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.prePayDiscount === 20}
                          onChange={({ target: { checked } }) => {
                            dispatch({
                              type: 'SET_PREPAY_DISCOUNT',
                              payload: checked ? 20 : 0,
                            })
                            calculateSalesTax()
                          }}
                        />
                      }
                      label={
                        <>
                          <Typography
                            variant="h5"
                            sx={{ display: 'inline-block' }}
                          >
                            SAVE 20%
                          </Typography>
                          <Typography>
                            by pre-paying your 5 year Monthly Plan
                          </Typography>
                        </>
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid
              container
              sx={{ paddingTop: '1rem' }}
              justifyContent="flex-end"
            >
              <Typography variant="h4">
                TOTAL{' '}
                {state.isIndonesia &&
                  indonesiaCurrency(state.order.idrAmount + state.order.idrTax)}
                {!state.isIndonesia &&
                  usCurrency(state.order.amount + state.order.tax)}
              </Typography>
            </Grid>
            <Grid container sx={{ paddingTop: '1rem' }} justifyContent="center">
              <LoadingButton
                onClick={() => nextPage()}
                loading={loading}
                variant="contained"
                size="large"
              >
                {state.packageInfo.id > 0 ? t('SUBMIT') : t('NEXT')}
              </LoadingButton>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Box>
  )
}
