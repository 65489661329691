import React, { useState, useContext, useEffect } from 'react'
import {
  Alert,
  Box,
  Card,
  CardContent,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  FormControl,
  FormGroup,
  InputLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { DatePicker } from '@mui/x-date-pickers'
import Scroll from 'react-scroll'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import MaskedInput from 'react-text-mask'
import qs from 'qs'
import { store } from '../../components/store'
import { Countries } from '../../lib/countries'
import {
  gdprCountries,
  arrayOfStates,
  getUserAge,
  padWithZero,
} from '../../lib/utilities'

export default function Registration() {
  const { dispatch, state } = useContext(store)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const theme = useTheme()
  const [agreement, setAgreement] = useState(false)
  const [loading, setLoading] = useState(false)
  const [formKey, setFormKey] = useState(Date.now())
  const [selectedCountry, setSelectedCountry]: any = useState(null)
  const [compPlanUrl, setCompPlanUrl]: any = useState(
    'https://corp.cf.talkfusion.com/website/docs/global-comp-plans/comp_plan_7.14.2022_EN.pdf'
  )
  const [errorMessage, setErrorMessage] = useState('')
  const [indonesiaUserId, setIndonesiaUserId] = useState('nik')
  const Element = Scroll.Element
  const scroller = Scroll.scroller

  useEffect(() => {
    if (selectedCountry && state.country !== selectedCountry.name) {
      setFormKey(Date.now())
    }
    const country = Countries.find((c) => c.value === state.country)
    if (country) {
      setSelectedCountry(country)
    }

    if (country?.value === 'ID') {
      setCompPlanUrl(
        'https://corp.cf.talkfusion.com/website/docs/id-comp-plans/comp_plan_7.14.2022_IN_EN.pdf'
      )
    }
  }, [state.country])

  const getCountOfDigits = (str: string) => {
    return str.replace(/[^0-9]/g, '').length
  }

  const validateRegistration = () => {
    const {
      password,
      confirmPassword,
      email,
      confirmEmail,
      birthDate,
      addressLine1,
      phone,
      city,
      stateProvince,
      postalCode,
      npwp,
      nik,
    } = state.registration

    return new Promise((resolve, reject): void => {
      if (
        !password ||
        !email ||
        !addressLine1 ||
        !phone ||
        !city ||
        !stateProvince ||
        !postalCode
      ) {
        reject(new Error('Missing required field'))
      }

      if (!/.{6,20}/.test(password)) {
        reject(new Error('Invalid password length'))
      }

      if (password !== confirmPassword) {
        reject(new Error('Passwords do not match'))
      }

      if (
        state.country !== 'ID' &&
        getUserAge(birthDate.dateTime.toISODate()) < 18
      ) {
        reject(new Error(t('FORM_ERROR_18_OR_OLDER')))
      }

      if (
        state.country === 'US' &&
        !arrayOfStates.includes(stateProvince.toUpperCase())
      ) {
        reject(new Error('Invalid state or province'))
      }

      if (email !== confirmEmail) {
        reject(new Error('Emails do not match'))
      }

      if (state.isIndonesia && !nik && !npwp) {
        reject(new Error('NPWP or NIK or required'))
      }

      if (state.isIndonesia && nik && !/\d{16}/.test(nik)) {
        reject(new Error('NIK needs to be 16 digits'))
      }

      if (
        state.isIndonesia &&
        npwp &&
        !/\d{15}/.test(npwp.replace(/\.|-/g, ''))
      ) {
        reject(new Error('NPWP needs to be 15 digits'))
      }

      if (state.isIndonesia && getCountOfDigits(phone) < 10) {
        reject(new Error('Phone number must be a minimum of 10 digits'))
      }

      resolve(null)
    })
  }

  const goToPayment = async () => {
    setLoading(true)

    try {
      setErrorMessage('')
      await validateRegistration()

      const createAccountPayload: any = {
        sponsorId: state.sponsor.account?.accountNumber,
        placementId: state.placementId,
        program: state.registration.program,
        side: state.placement,
        planId: state.registration.planId,
        packageId: state.registration.packageId,
        fd14: state.registration.birthDate.text,
        country: state.country,
        fd10: state.registration.password,
        fd11: state.registration.confirmPassword,
        fd12: state.registration.firstName,
        fd13: state.registration.lastName,
        fd15: state.registration.email,
        fd16: state.registration.confirmEmail,
        'date-of-birth': state.registration.birthDate.text,
        fd17: state.registration.company,
        fd23: state.registration.phone,
        fd18: state.registration.addressLine1,
        fd19: state.registration.addressLine2,
        fd20: state.registration.city,
        fd21: state.registration.stateProvince,
        fd22: state.registration.postalCode,
      }

      if (createAccountPayload.fd17 === 'TFAPP') {
        createAccountPayload.fd17 = ''
        dispatch({ type: 'SET_TFAPP', payload: true })
      }

      // Birthdate not required for Indonesian country
      if (state.isIndonesia) {
        delete createAccountPayload['date-of-birth']
        createAccountPayload.npwp = state.registration.npwp
        createAccountPayload.nik = state.registration.nik
      }

      // Set planId based on the package
      if (state.registration.packageId === 0) {
        createAccountPayload.planId = 0 // if user is not interested in a product package, planId is 0
      } else if (state.registration.packageId === 1) {
        createAccountPayload.planId = 'Q' // if the user chose "Video Email" (packageId 1), then planId = Q
      } else {
        createAccountPayload.planId = 'S' // planId is S if user selects Elite, Executive, or Pro
      }

      const { data: memberId } = await axios({
        method: 'POST',
        url: 'https://signup.talkfusion.com/jointf/_create_account.asp',
        data: qs.stringify(createAccountPayload),
      })

      dispatch({ type: 'COPY_REGISTRATION_TO_PAYMENT' })
      dispatch({ type: 'SET_MEMBER_ID', payload: memberId })
      navigate('/review')
    } catch (err: any) {
      setErrorMessage(err.message)
      scroller.scrollTo('registration', {
        duration: 1500,
        smooth: true,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box
      key={formKey}
      sx={{
        backgroundColor: theme.palette.grey[100],
      }}
    >
      <Element name="registration"></Element>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          py: 8,
        }}
      >
        {state.sponsor && (
          <Typography variant="caption" sx={{ mb: 1, opacity: 0.7 }}>
            Sponsor: ({state.sponsor.accountNumber})
          </Typography>
        )}
        <Card sx={{ mb: 2 }}>
          <CardContent sx={{ p: 4 }}>
            {errorMessage && (
              <Alert severity="error" sx={{ mb: 3 }}>
                {errorMessage}
              </Alert>
            )}
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label={t('PASSWORD')}
                  helperText="6-20 characters"
                  required
                  type="password"
                  defaultValue={state.registration.password}
                  inputProps={{ maxLength: 20 }}
                  onBlur={(e) =>
                    dispatch({
                      type: 'SET_REGISTRATION',
                      payload: { password: e.target.value },
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label={t('CONFIRM_PASSWORD')}
                  helperText="6-20 characters"
                  required
                  type="password"
                  defaultValue={state.registration.confirmPassword}
                  inputProps={{ maxLength: 20 }}
                  onBlur={(e) =>
                    dispatch({
                      type: 'SET_REGISTRATION',
                      payload: { confirmPassword: e.target.value },
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label={t('FIRST_NAME')}
                  required
                  defaultValue={state.registration.firstName}
                  onBlur={(e) =>
                    dispatch({
                      type: 'SET_REGISTRATION',
                      payload: { firstName: e.target.value },
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label={t('LAST_NAME')}
                  required
                  defaultValue={state.registration.lastName}
                  onBlur={(e) =>
                    dispatch({
                      type: 'SET_REGISTRATION',
                      payload: { lastName: e.target.value },
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label={t('EMAIL')}
                  required
                  defaultValue={state.registration.email}
                  onBlur={(e) =>
                    dispatch({
                      type: 'SET_REGISTRATION',
                      payload: { email: e.target.value },
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label={t('CONFIRM_EMAIL')}
                  required
                  defaultValue={state.registration.confirmEmail}
                  onBlur={(e) =>
                    dispatch({
                      type: 'SET_REGISTRATION',
                      payload: { confirmEmail: e.target.value },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  label={t('FORM_DATE_OF_BIRTH')}
                  inputFormat="MM-dd-yyyy"
                  mask="__-__-____"
                  value={state.registration.birthDate.text}
                  onChange={(val: any) => {
                    dispatch({
                      type: 'SET_REGISTRATION',
                      payload: {
                        birthDate: {
                          dateTime: val,
                          text: `${padWithZero(val.year)}-${padWithZero(
                            val.month
                          )}-${padWithZero(val.day)}`,
                        },
                      },
                    })
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      fullWidth
                      {...params}
                      required
                      error={false}
                      helperText="mm-dd-yyyy"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t('FORM_COMPANY_NAME')}
                  defaultValue={state.registration.company}
                  onBlur={(e) =>
                    dispatch({
                      type: 'SET_REGISTRATION',
                      payload: { company: e.target.value },
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={selectedCountry ? selectedCountry.name : ''}
                  fullWidth
                  disabled
                  label={t('COUNTRY')}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label={t('FORM_RESIDENT_PHONE')}
                  helperText="#-###-###-####"
                  defaultValue={state.registration.phone}
                  inputProps={{ maxLength: 30 }}
                  onBlur={(e) =>
                    dispatch({
                      type: 'SET_REGISTRATION',
                      payload: { phone: e.target.value },
                    })
                  }
                />
              </Grid>
              {state.isIndonesia && (
                <>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel>Identification</InputLabel>
                      <Select
                        value={indonesiaUserId}
                        label="Identification"
                        onChange={(e) => {
                          dispatch({
                            type: 'SET_REGISTRATION',
                            payload: { npwp: '', nik: '' },
                          })
                          setIndonesiaUserId(e.target.value)
                        }}
                      >
                        <MenuItem value="nik">NIK</MenuItem>
                        <MenuItem value="npwp">NPWP</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    {state.isIndonesia && indonesiaUserId === 'npwp' && (
                      <MaskedInput
                        mask={[
                          /[0-9]/,
                          /[0-9]/,
                          '.',
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          '.',
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          '.',
                          /[0-9]/,
                          '-',
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          '.',
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                        ]}
                        defaultValue={state.registration.npwp}
                        onBlur={(e) =>
                          dispatch({
                            type: 'SET_REGISTRATION',
                            payload: { npwp: e.target.value },
                          })
                        }
                        render={(ref, props) => (
                          <TextField
                            inputRef={ref}
                            fullWidth
                            label="NPWP"
                            helperText="##.###.###.#-###.###"
                            {...props}
                          />
                        )}
                      />
                    )}
                    {state.isIndonesia && indonesiaUserId === 'nik' && (
                      <MaskedInput
                        mask={[
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                        ]}
                        onBlur={(e: any) =>
                          dispatch({
                            type: 'SET_REGISTRATION',
                            payload: { nik: e.target.value },
                          })
                        }
                        defaultValue={state.registration.nik}
                        render={(ref, props) => (
                          <TextField
                            inputRef={ref}
                            fullWidth
                            label="NIK"
                            helperText="################"
                            {...props}
                          />
                        )}
                      />
                    )}
                  </Grid>
                </>
              )}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label={t('FORM_ADDRESS_LINE_1')}
                  required
                  defaultValue={state.registration.addressLine1}
                  onBlur={(e) =>
                    dispatch({
                      type: 'SET_REGISTRATION',
                      payload: { addressLine1: e.target.value },
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label={t('FORM_ADDRESS_LINE_2')}
                  defaultValue={state.registration.addressLine2}
                  onBlur={(e) =>
                    dispatch({
                      type: 'SET_REGISTRATION',
                      payload: { addressLine2: e.target.value },
                    })
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label={t('FORM_CITY')}
                  required
                  defaultValue={state.registration.city}
                  onBlur={(e) =>
                    dispatch({
                      type: 'SET_REGISTRATION',
                      payload: { city: e.target.value },
                    })
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label={t('FORM_STATE_PROVINCE')}
                  helperText="e.g. AR, TX, NY..."
                  defaultValue={state.registration.stateProvince}
                  onBlur={(e) =>
                    dispatch({
                      type: 'SET_REGISTRATION',
                      payload: { stateProvince: e.target.value },
                    })
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label={t('FORM_POSTAL_CODE')}
                  defaultValue={state.registration.postalCode}
                  onBlur={(e) =>
                    dispatch({
                      type: 'SET_REGISTRATION',
                      payload: { postalCode: e.target.value },
                    })
                  }
                />
              </Grid>
            </Grid>
            <Divider sx={{ my: 6 }} />
            <Box>
              <Typography variant="h5">
                {t('SIGNUP_STEP2_ESIGN_NOTICE_TITLE')}
              </Typography>
              <Typography sx={{ pb: 2 }}>
                {t('SIGNUP_STEP2_ESIGN_NOTICE_SUBTITLE')}
              </Typography>
              <ol>
                <li>
                  <Typography>
                    {t('SIGNUP_STEP2_ESIGN_NOTICE_BODY_1')}
                  </Typography>
                </li>
                <li>
                  <Typography>
                    {t('SIGNUP_STEP2_ESIGN_NOTICE_BODY_2')}
                  </Typography>
                </li>
                <li>
                  <Typography>
                    {t('SIGNUP_STEP2_ESIGN_NOTICE_BODY_3')}
                  </Typography>
                </li>
                <li>
                  <Typography>
                    {t('SIGNUP_STEP2_ESIGN_NOTICE_BODY_4')}
                  </Typography>
                </li>
                <li>
                  <Typography>
                    {t('SIGNUP_STEP2_ESIGN_NOTICE_BODY_5')}
                  </Typography>
                </li>
                <li>
                  <Typography>
                    {t('SIGNUP_STEP2_ESIGN_NOTICE_BODY_6')}
                  </Typography>
                </li>
                <li>
                  <Typography>
                    {t('SIGNUP_STEP2_ESIGN_NOTICE_BODY_7')}
                  </Typography>
                </li>
              </ol>
              <Box
                sx={{
                  border: `1px solid ${theme.palette.divider}`,
                  borderRadius: 2,
                  p: 2,
                  my: 4,
                  mx: 5,
                }}
              >
                <Typography variant="h5">
                  {state.country !== 'ID' &&
                    t('SIGNUP_STEP2_ESIGN_NOTICE_REFUND_CANCELLATION')}
                </Typography>
                <i>
                  <Typography>
                    {state.country !== 'ID' &&
                      t('SIGNUP_STEP2_REFUND_CANCELLATION_POLICY_BODY')}
                  </Typography>
                </i>
                <FormGroup sx={{ my: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={({ target: { checked } }) =>
                          setAgreement(checked)
                        }
                      />
                    }
                    label="I Agree"
                  />
                  <Typography sx={{ ml: 4, color: theme.palette.grey[600] }}>
                    {t('SIGNUP_STEP2_ESIGN_NOTICE_AGREEMENT_DISCLAIMER')}
                  </Typography>
                </FormGroup>
                <ul>
                  <li>
                    <Typography>
                      <a
                        target="_blank"
                        rel="noreferrer noopener"
                        href="https://signup.talkfusion.com/jointf/docs/legalus/TOS-en.html"
                      >
                        {t('SIGNUP_STEP2_ESIGN_NOTICE_TERMS_OF_SERVICE')}
                      </a>
                    </Typography>
                  </li>
                  {state.country !== 'ID' && (
                    <li>
                      <Typography>
                        <a
                          target="_blank"
                          rel="noreferrer noopener"
                          href="https://signup.talkfusion.com/jointf/docs/legalus/Refund-en.html"
                        >
                          {t('SIGNUP_STEP2_ESIGN_NOTICE_REFUND_CANCELLATION')}
                        </a>
                      </Typography>
                    </li>
                  )}
                  <li>
                    <Typography>
                      <a
                        target="_blank"
                        rel="noreferrer noopener"
                        href={compPlanUrl}
                      >
                        {t('SIGNUP_STEP2_ESIGN_NOTICE_COMPENSATION_PLAN')}
                      </a>
                    </Typography>
                  </li>
                  {state.registration.programId === 1 && (
                    <>
                      <li>
                        <Typography>
                          <a
                            target="_blank"
                            rel="noreferrer noopener"
                            href="https://signup.talkfusion.com/jointf/docs/legalus/IncomeDisclosure-en.pdf"
                          >
                            {t('SIGNUP_STEP2_ESIGN_NOTICE_INCOME_DISCLOSURE')}
                          </a>
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <a
                            target="_blank"
                            rel="noreferrer noopener"
                            href="https://signup.talkfusion.com/jointf/docs/legalus/Conditions-en.html"
                          >
                            {t(
                              'SIGNUP_STEP2_ESIGN_NOTICE_TERMS_AND_CONDITIONS'
                            )}
                          </a>
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <a
                            target="_blank"
                            rel="noreferrer noopener"
                            href="https://signup.talkfusion.com/jointf/docs/legalus/Policies-en.html"
                          >
                            {t(
                              'SIGNUP_STEP2_ESIGN_NOTICE_POLICIES_AND_PROCEDURES'
                            )}
                          </a>
                        </Typography>
                      </li>
                    </>
                  )}
                  <li>
                    <Typography>
                      <a
                        target="_blank"
                        rel="noreferrer noopener"
                        href="https://signup.talkfusion.com/jointf/docs/legalus/Privacy-en.html"
                      >
                        {t('SIGNUP_STEP2_ESIGN_NOTICE_PRIVACY_POLICY')}
                      </a>
                    </Typography>
                  </li>
                  {state.isIndonesia && (
                    <li>
                      <Typography>
                        <a
                          target="_blank"
                          rel="noreferrer noopener"
                          href="https://signup.talkfusion.com/jointf/docs/legalus/StarterKitTerms-EN.html"
                        >
                          Starter Kit Terms
                        </a>
                      </Typography>
                    </li>
                  )}
                  {gdprCountries.includes(state.country) && (
                    <li>
                      <Typography>
                        <a
                          target="_blank"
                          rel="noreferrer noopener"
                          href="https://signup.talkfusion.com/jointf/docs/legalus/Data-Transfer-Agreement.pdf"
                        >
                          Data Transfer Agreement
                        </a>
                      </Typography>
                    </li>
                  )}
                </ul>
              </Box>
            </Box>
            <Box sx={{ mx: 5, mb: 1 }}>
              <LoadingButton
                fullWidth
                disabled={!agreement}
                loading={loading}
                variant="contained"
                size="large"
                onClick={goToPayment}
              >
                {t('NEXT')}
              </LoadingButton>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Box>
  )
}
